.home-welcome {
  display: flex;
  justify-content: space-between;
  padding-top: 200px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.home-welcome .left {
  width: 55%;
  z-index: 10;
}

.home-welcome .right {
  width: 45%;
  z-index: 10;
  height: 580px;
  text-align: end;
}

.home-welcome .right img {
  max-height: 580px;
  max-width: 100%;
}

.home-welcome .left h1 {
  font-family: "outfit";
  font-size: 52px;
  font-weight: 700;
  color: #f6c893;
  line-height: 65px;
  margin-bottom: 0px;
  margin-left: 20%;
  margin-top: 0px;
  margin-right: 10px;
  text-transform: capitalize;
}

.home-welcome .left p {
  font-family: "roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #faf4f4;
  margin-top: 12px;
  margin-left: 20%;
  margin-right: 10px;
  text-transform: capitalize;
}

.home-welcome .left a {
  text-decoration: none;
}

.home-welcome .left button {
  margin-top: 10%;
  background-color: #b98860;
  border-radius: 32px;
  font-family: "roboto";
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 1.1px;
  border-width: 0px;
  color: white;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: 400ms;
}

.home-welcome .left button:hover {
  scale: 1.1;
}

.home-welcome .left ul {
  display: flex;
  list-style: none;
  margin-top: 10%;
  justify-content: center;
  padding-left: 0px;
}

.home-welcome .left ul li {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.home-welcome .left ul li img {
  width: 40px;
  height: 30px;
}

.home-welcome .left ul li p {
  font-family: "roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.1;
  color: #faf4f4;
  padding: 0;
  margin: 0;
  margin-top: 12px;
  text-transform: capitalize;
}

.navbar {
  position: absolute;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
}

@media only screen and (max-width: 900px) {
  .home-welcome {
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
  }

  .home-welcome .left {
    width: 100%;
  }

  .home-welcome .left p {
    margin-left: 0;
  }

  .home-welcome .left h1 {
    margin-left: 0;
  }

  .home-welcome .right {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }

  .home-welcome .right img {
    border-radius: 16px;
    max-width: 100%;
  }
}
