.testimonials-layout {
  margin-top: 200px;
  text-align: center;
}

.testimonials-layout #type {
  color: #f6c893;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  font-family: "outfit";
}

.testimonials-layout h2 {
  color: white;
  font-family: "outfit";
  font-weight: 700;
  font-size: 50px;
  line-height: 55px;
  text-transform: capitalize;
  margin-top: 24px;
}

.testimonials-layout .testimonials-container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 20px;
  padding-top: 50px;
  width: 100%;
}

.testimonials-layout .testimonials-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.testimonials-layout .testimonials-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.testimonials-layout .testimonial-container:first-child {
  margin-left: 30px;
}
