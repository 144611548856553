html {
  scroll-behavior: smooth;
}

.apartment-availability .background {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  padding-top: 200px;
}

.apartment-availability .introduction {
  text-align: center;
  width: 100%;
  padding-bottom: 140px;
}

.apartment-availability .introduction h3 {
  font-family: "roboto";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 1.1;
  color: #f6c893;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.apartment-availability .introduction h2 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  color: #faf4f4;
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 0px;
}

.apartment-availability .introduction p {
  font-family: "outfit";
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #dadada;
  max-width: 600px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.apartment-availability .choose-date {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: space-between;
  background-color: #251f1f;
  border-radius: 30px 32px 0px 0px;
  height: 180px;
  margin-top: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

.apartment-availability .book-stay {
  background-color: #251f1f;
  text-align: center;
  align-items: center;
  border-radius: 30px;
  margin: 0;
  height: 100px;
  padding-top: 30px;
  margin-bottom: -60px;
  display: inline-block;
  padding-left: 40px;
  padding-right: 40px;
}

.apartment-availability .book-stay p {
  font-family: "roboto";
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #faf4f4;
  margin: 0;
  text-transform: capitalize;
}

.apartment-availability .choose-date .choose-date-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.apartment-availability .choose-date .date-container {
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
}

.apartment-availability .choose-date .choose-date-item .date-container img {
  width: 45px;
  height: 50px;
  text-align: center;
  margin-left: 32px;
}

.apartment-availability .choose-date .choose-people-count {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 160px;
}

.apartment-availability .choose-date .choose-people-count .date-title {
  margin-left: 0;
}

.apartment-availability .choose-date .choose-people-count .people-count {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  justify-content: space-between;
}

.apartment-availability .choose-date .choose-people-count .people-count img {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.apartment-availability .choose-date .choose-people-count .people-count p {
  font-family: "roboto";
  font-weight: 100;
  font-size: 50px;
  line-height: 50px;
  color: #faf4f4;
  margin: 0;
}

.apartment-availability .choose-date .date-title {
  font-family: "roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1.1;
  color: #faf4f4;
  text-transform: uppercase;
  margin-top: 22px;
}

.apartment-availability .choose-date .date-container .month-name {
  font-family: "roboto";
  font-weight: 100;
  font-size: 50px;
  line-height: 50px;
  color: #faf4f4;
  margin: 0;
}

.apartment-availability .choose-date .date-container .month-day {
  font-family: "roboto";
  font-weight: 200;
  font-size: 30px;
  line-height: 30px;
  color: #faf4f4;
  margin: 0;
}

.apartment-availability .choose-date .confirm-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.apartment-availability .choose-date .confirm-button-container button {
  background-color: #b98860;
  height: 60px;
  text-transform: uppercase;
  font-family: "roboto";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #faf4f4;
  border-width: 0px;
  border-radius: 32px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  cursor: pointer;
  top: 50%;
}

.navbar {
  position: absolute;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
}

.calendar-container {
  padding: 5px;
  background-color: #251f1f;
  border-width: 0px;
  display: block;
  max-width: 350px;
  border-radius: 20px;
  position: relative;
  bottom: 220px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.75));
  left: 20px;
}

.calendar-container .react-calendar {
  position: absolute;
}

.calendar-container .react-calendar {
  border-color: #251f1f;
  background-color: #251f1f;
}

.react-calendar__navigation button {
  min-width: 44px;
  font-size: 16px;
}

.calendar-container .react-calendar .react-calendar__navigation__label {
  background-color: #251f1f;
}

.calendar-container .react-calendar .react-calendar__navigation__label:enabled {
  background-color: #251f1f;
}

.calendar-container .react-calendar__navigation button:active,
.calendar-container .react-calendar__navigation button:enabled {
  background-color: #251f1f;
}

.calendar-container .react-calendar button:disabled {
  color: #636363;
  background-color: #251f1f;
}

.react-calendar__navigation button[disabled] {
  background-color: #251f1f;
}
.react-calendar__navigation button[enabled] {
  background-color: #251f1f;
}
abbr[title] {
  text-decoration: none;
  color: #faf4f4;
  background-color: #251f1f;
}

.calendar-container button {
  background-color: #251f1f;
  color: #e7e7e7;
}

.calendar-container .react-calendar button {
  background-color: #251f1f;
}

.calendar-container .react-calendar button:active {
  background-color: #251f1f;
}

.calendar-container .react-calendar .react-calendar__navigation__arrow:enabled {
  background-color: #251f1f;
}

.calendar-container
  .react-calendar
  .react-calendar__navigation__arrow:enabled:hover {
  background-color: #251f1f;
  transition: 0.5s;
}

.calendar-container .react-calendar button:enabled:hover {
  background-color: #b98860;
  transition: 0.4s;
  color: #e7e7e7;
  font-weight: bold;
}

.calendar-container .react-calendar__tile--range {
  background-color: #b98860;
}

.calendar-container .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #251f1f;
  border-radius: 6px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
}

.calendar-container .react-calendar__tile--hasActive:enabled:hover,
.calendar-container .react-calendar__tile--hasActive:enabled:focus {
  background-color: none;
}

.calendar-container .react-calendar__tile--active {
  font-weight: bold;
  background-color: #b98860;
}

.calendar-container .react-calendar__tile--active:disabled:hover {
  background-color: #b98860;
}

.calendar-container .react-calendar__tile--active:enabled:hover,
.calendar-container .react-calendar__tile--active:enabled:focus {
  background-color: #b98860;
}

.calendar-container .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #b98860;
}

.calendar-container .react-calendar .react-calendar__tile--range {
  background: #b98860;
  border-radius: 2;
}

.calendar-container .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: white;
  background-color: #b98860;
}

.calendar-container .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
  background-color: #b98860;
}

@media only screen and (max-width: 1200px) {
  .apartment-availability .choose-date {
    justify-content: space-between;
    height: auto;
  }

  .apartment-availability .choose-date .date-container .month-name {
    font-size: 35px;
    line-height: 35px;
  }

  .apartment-availability .choose-date .choose-date-item .date-container img {
    width: 30px;
    height: 30px;
    margin-left: 20px;
  }

  .apartment-availability .choose-date .date-container .month-day {
    font-size: 20px;
    line-height: 20px;
  }

  .apartment-availability .choose-date .confirm-button-container button {
    font-size: 16px;
  }

  .apartment-availability .choose-date .choose-people-count {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 120px;
  }

  .apartment-availability .choose-date .date-title {
    font-size: 14px;
  }

  .apartment-availability .choose-date .choose-people-count .people-count {
    align-items: center;
  }

  .apartment-availability .choose-date .choose-people-count .people-count p {
    font-size: 35px;
  }

  .apartment-availability .choose-date .choose-people-count .people-count img {
    width: 30px;
    height: 30px;
    padding: 0;
  }
}

@media only screen and (max-width: 850px) {
  .apartment-availability .choose-date .confirm-button-container button {
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .apartment-availability .choose-date {
    justify-content: space-between;
    height: auto;
    grid-template-columns: auto auto;
    row-gap: 20px;
  }

  .apartment-availability .choose-date .confirm-button-container {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media only screen and (max-width: 500px) {
  .calendar-container {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
