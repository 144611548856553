.house-stats {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 200px;
}

.house-stats .house-numbers {
  justify-content: space-between;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.house-stats .house-numbers .stat-container {
  text-align: center;
}

.house-stats .stat-container h5 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  color: #faf4f4;
  margin-bottom: 0px;
  margin-top: 0px;
}

.house-stats .stat-container p {
  font-family: "roboto";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #f6c893;
  text-transform: uppercase;
  margin-top: 12px;
}

.house-stats .house-rating {
  text-align: center;
  margin-top: 140px;
}

.house-stats .house-rating h3 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  margin-top: 0px;
  margin-bottom: 0;
  color: #faf4f4;
  text-transform: capitalize;
}

.house-stats .house-rating h5 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  color: #fbbc05;
  margin-top: 28px;
  margin-bottom: 0px;
}

.house-stats .house-rating p {
  font-family: "roboto";
  font-weight: 500;
  font-size: 18px;
  margin-top: 28px;
  color: #e7e7e7;
  text-transform: capitalize;
}

@media only screen and (max-width: 1000px) {
  .house-stats {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .house-stats .house-numbers {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
}
