.home-features {
  padding-top: 60px;
}

.home-features h1 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  color: white;
  margin-left: 10%;
}

@media only screen and (max-width: 950px) {
  .home-features h1 {
    margin-left: 5%;
  }
}
