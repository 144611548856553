.check-availability {
  background-color: #251f1f;
  padding-top: 100px;
  padding-left: 5%;
  padding-right: 5%;
}

.check-availability h5 {
  margin-top: 0px;
  font-family: "oxygen";
  color: #faf4f4;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-transform: capitalize;
}

.check-availability .details {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
}

.check-availability p {
  color: #d9d9d9;
  font-family: "roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

@media only screen and (max-width: 700px) {
  .check-availability .details {
    grid-template-columns: auto auto;
    justify-content: space-between;
    column-gap: 20px;
  }
}
