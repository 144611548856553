.footer-component {
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  background-color: #1f1a1a;
  margin-top: 200px;
}

.footer-component .footer-item h5 {
  font-family: "roboto";
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #faf4f4;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.footer-component .travel-items {
  display: flex;
}

.footer-component .travel-items .travel-item {
  text-align: center;
}

.footer-component .travel-items .travel-item:first-child {
  margin-right: 10px;
}

.footer-component .travel-items .travel-item a {
  font-family: "roboto";
  font-weight: 600;
  font-size: 16px;
  color: #faf4f4;
  letter-spacing: 0.1em;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  margin-top: 12px;
}

.footer-component .travel-items .travel-item img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.footer-component .footer-item ul {
  padding-left: 0px;
  margin-top: 0px;
}

.footer-component .footer-item ul li {
  list-style: none;
  margin-bottom: 10px;
}

.footer-component .footer-item ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #f6c893; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.footer-component .footer-item ul li a {
  color: white;
  font-family: "roboto";
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}

.footer-component .footer-item .house-item,
.footer-component .footer-item .contact-item {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.footer-component .footer-item .house-item img,
.footer-component .footer-item .contact-item img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.footer-component .footer-item .house-item p {
  font-family: "roboto";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: white;
  text-transform: uppercase;
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
}

.footer-component .footer-item .contact-item a {
  font-family: "roboto";
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: white;
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
}

.signature-container {
  background-color: #1f1a1a;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  color: #faf4f4;
  padding-bottom: 6px;
}

.signature-container p {
  margin-top: 0px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1000px) {
  .footer-component {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .footer-component .footer-item .contact-item a,
  .footer-component .footer-item .house-item p,
  .footer-component .footer-item ul li a,
  .footer-component .footer-item .house-item p {
    font-size: 14px;
  }

  .footer-component .travel-items .travel-item img {
    width: 24px;
    height: 24px;
  }

  .footer-component .footer-item h5 {
    font-size: 18px;
  }

  .footer-component .travel-items .travel-item a,
  .footer-component .footer-item .house-item p {
    font-size: 12px;
  }
}
