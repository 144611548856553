.images-slider-component {
  margin-top: 160px;
}

.images-slider-component h5 {
  font-family: "roboto";
  font-size: 30px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.2em;
  color: #f6c893;
  text-transform: uppercase;
  margin-left: 10%;
  padding-bottom: 20px;
}

.images-slider-component .slide-container {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  max-width: 1100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.images-slider-component .slide-container .each-slide {
  position: relative;
  max-height: 600px;
}

.images-slider-component .slide-container .lazy {
  width: 100%;
  max-height: 600px;
  border-radius: 24px;
}

.images-slider-component .slide-container .indicator {
  display: none;
}

.images-slider-component .slide-container button {
  margin: 20px;
}

@media only screen and (max-width: 1000px) {
  .images-slider-component .slide-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .images-slider-component h5 {
    margin-left: 5%;
  }
}
