.feature-component {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
  background-color: #251f1f;
}

.feature-component .left {
  width: 40%;
  align-items: center;
}

.feature-component .left h3 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 28px;
  color: white;
  margin-bottom: 0px;
  text-align: start;
  color: #f6c893;
  line-height: 35px;
  margin-top: 0px;
}

.feature-component .left p {
  font-family: "roboto";
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-top: 12px;
  text-align: start;
}

.feature-component .right {
  width: 55%;
  align-items: center;
}

.feature-component .right img {
  width: 100%;
  border-radius: 20px;
  max-height: 700px;
}

.feature-component .left a {
  text-decoration: none;
}

.feature-component .left button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;
  color: white;
  background-color: #b98860;
  font-family: "roboto";
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.1;
  line-height: 23px;
  text-transform: uppercase;
  border-radius: 32px;
  border-width: 0px;
  margin-top: 90px;
  letter-spacing: 0.1em;
  transition: 400ms;
}

.feature-component .left button:hover {
  scale: 1.1;
}

@media only screen and (max-width: 950px) {
  .feature-component {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 750px) {
  .feature-component {
    display: block;
  }

  .feature-component .left,
  .feature-component .right {
    width: 100%;
  }

  .feature-component .right img {
    margin-top: 90px;
  }
}
