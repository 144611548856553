.testimonials-component {
  text-align: center;
  margin-right: 30px;
  background-color: #251f1f;
  width: 300px;
  min-width: 300px;
  padding: 50px;
  margin-top: 101px;
  box-shadow: 0px 4px 20px rgba(255, 255, 255, 0.08);
  border-radius: 20px;
}

.testimonials-component img {
  width: 50px;
}

.testimonials-component #review {
  color: #f3efe0;
  font-size: 18px;
  font-family: "roboto";
  font-weight: 400;
  line-height: 27.5px;
}

.testimonials-component .reviewer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 46px;
}

.testimonials-component .reviewer p {
  text-align: start;
  margin-left: 20px;
  margin-bottom: 0px;
}

.testimonials-component #name {
  text-transform: uppercase;
  font-family: "outfit";
  font-weight: 600;
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
}

.testimonials-component #reviewer-title {
  font-family: "roboto";
  font-size: 18px;
  font-weight: 400;
  color: #f3efe0;
  line-height: 27.5px;
  margin-top: 0px;
}

.testimonials-component:first-child {
  margin-left: 30px;
}
