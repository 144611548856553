.map-container {
  padding-left: 15%;
  padding-right: 15%;
  margin-top: 200px;
}

.map-container h2 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

.google-map {
  height: 500px;
  padding: 0px;
  margin: 0;
  margin-top: 60px;
}
