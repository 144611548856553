.navbar {
  position: absolute;
}

.welcome-about-component {
  background-color: #251f1f;
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 200px;
  padding-bottom: 40px;
}

.welcome-about-component .left {
  width: 40%;
  margin-right: 10%;
}

.welcome-about-component .left h5 {
  font-family: "roboto";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #f6c893;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.welcome-about-component .left h1 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  color: #faf4f4;
  margin-top: 20px;
  text-transform: capitalize;
}

.welcome-about-component .left p {
  font-family: "roboto";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  margin-top: 40px;
  color: #faf4f4;
}

.welcome-about-component .right {
  width: 60%;
  text-align: end;
}

.welcome-about-component .right img {
  width: 100%;
  border-radius: 20px;
}

@media only screen and (max-width: 1000px) {
  .welcome-about-component {
    flex-direction: column;
  }

  .welcome-about-component .left,
  .welcome-about-component .right {
    width: 100%;
  }

  .welcome-about-component .right {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .welcome-about-component .left h5 {
    font-size: 16px;
    line-height: 18px;
  }

  .welcome-about-component .left h1 {
    font-size: 38px;
    line-height: 40px;
  }

  .welcome-about-component .left p {
    font-size: 15px;
  }
}
